/* common padding */

.radius-card{
    border-radius: 4px;
}


.set-loader {
    position: fixed;
    z-index: 99999;
    top: 57%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    right: 0;
    stroke: #66b6e0;
    fill: #66b6e0;
  }
/* login styling styling*/
@font-face {
    font-family: "Karla";
    src:url('./../assets/fonts/Karla-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: "Montserrat";
    src:url('./../assets/fonts/Montserrat-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

body{
    font-family: "Karla";
    /* background-color: #d0d0ce; */
    
    min-height: 100vh;
}
.loginbackground{
    /* background: #d0d0ce !important; */
    /* background-color: #0063B2FF !important; */
}

.login-card-img{
    border-radius: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.login-card{
    border: none !important;

    border-radius: 27.5px;
    box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
    overflow: hidden;
}

.login-card .card-body{
    padding: 85px 60px 60px;
}

.login-card-description{
    font-size: 25px;
    color: #000;
    font-weight: normal !important;
    margin-bottom: 23px;
}

.title-login-page{
    display: block;
    font-size: 36px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 23px;
}


.login-card .form-control{
    border: 1px solid #d5dae2;
    padding: 20px 25px;
    /* margin-bottom: 20px; */
    min-height: 45px;
    font-size: 15px;
    line-height: 2;
    font-weight: normal;
    background: #ffffff;
}
.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.form-control{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
   
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::placeholder{
    
    color: black;
}
.login-card .login-btn{
    padding: 13px 20px 12px;
    background-color: #000;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    margin-bottom: 24px;
    border: none;
}

.login-card form{
    max-width: 400px;
}

.login-card .forgot-password-link{
    font-size: 17px;
    color: #919aa3;
    margin-bottom: 12px;
}
.login-card-footer-text{
    font-size: 16px;
    color: #0d2366;
    margin-bottom: 60px;
}
.login-card-footer-nav a{
    font-size: 14px;
    color: #919aa3;
}


.err-msg{
    color: red;
    margin-top: 5px;
}

.blue-login-button{
    /* background: #002450 !important; */
    background: #5798ff !important;
}







.login-logo{
    font-size: 28px;
    /* font-weight: 500; */
    display: block;
    color: rgb(82, 82, 82);
    padding-bottom: 30px;
}
.heading-black{
    color: black;
}
.gradient-background{
    background: linear-gradient(rgb(252,155,153),rgb(134,178,248)) !important;
        /* z-index: -10; */
}
.background-pattern{
    background: linear-gradient(rgb(252, 155, 153),rgb(134,178,248)) !important;
    
    position: relative; 
    height: 100vh;
    /* opacity: 0.8; */
    background-size: cover;
}
.background-pattern::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.2;
    background-image: url('../assets/images/backgroundpattern.png');  
 
}
.gradient-45{
    background: linear-gradient(120deg,rgb(252,155,153),rgb(134,178,248)) !important;
}
.gradient-sidebar{
    /* background: #d0d0ce !important; */
    color: black;
    background: linear-gradient(90deg,rgb(252,155,153),rgb(134,178,248)) !important;
    /* background: linear-gradient(-45deg,rgb(252,155,153), rgb(134,178,248)) !important; */
	/* background-size: 400% 400% !important; */
	/* animation: gradient 2s ease infinite !important; */
}


/* login styloing end */

.sidebar-title-text{
    color: white;
    font-weight: 900;
    font-size: 24px;
}

.bgwhitetextblack{
    background: #ffffff !important;
    color: black !important;
}
.textblack{
    color: black !important;
}
.white-text{
    color: white;
    font-weight: 900;
}
.form-control::placeholder{
    color: rgba(48, 47, 47, 0.717);
    font-size: 16px;
}
.form-control{
}
/* .form-control{
    border: 1px solid rgb(93, 93, 93) !important;
    border: 1px solid rgb(136, 128, 128) !important;
    border:none !important;
    background: #f4f4f4 !important;
    border-radius: 10px !important;
   
     color: rgb(82, 82, 82);
} */


/* siode bar modifications */


.sidebar .nav .nav-item.active > .nav-link{
    font-weight: 600 !important;
    font-size: 24px !important;
    /* background: #efadb3; */
    background: none;
    /* color: black !important; */
}
.sidebar .nav .nav-item .nav-link{
    /* color: #002450 !important; */
    font-weight: 600;

    /* font-weight: bold; */
    line-height: 20px;
    color: #fff;
    
}
.sidebar .nav .nav-item .nav-link .menu-title{
    font-size: 17px !important;
    font-stretch: expanded;

}
.sidebar .nav .nav-item:hover> .nav-link{
    /* color: rgb(48, 42, 42) !important; */
    font-weight: 600;

}

.dashboard-cards{
    font-size: 18px;
    font-weight: 500;
}

/* Romantic things management styling start */
.heading-for-table{
    display: block;
    font-size: 25px;
    /* font-family: Arial, Helvetica, sans-serif; */
    /* font-family: Open Sans,sans-serif; */
    /* color: #000; */
    color: #0d2366;
    font-weight: 600;
}
.card-box-shadow{
    box-shadow: 0 1px 20px 0 rgba(69,90,100,.08) ;
    border-radius: 10px;
}
.Add-romantic-things-title{
    display: block;
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin-bottom: 18px;
}
.add-romanticthings{
    /* background: linear-gradient(90deg,rgb(252,155,153),rgb(134,178,248)); */
    border: none;
    background: #5798ff;
    font-size: 20px;
    padding-bottom: 10px;
    border-radius: 4px;
    height: 40px;
}
.ant-table{
    font-family: "Karla" !important;
    font-size: 16px;
}
.ant-btn > span{
    display: block !important;
    margin: auto;
    /* padding: 10px; */
}
/* .ant-modal-footer{
    display: none;
} */
.modal-radius{
    border-radius: 4px !important;
}
.ant-modal-content{
    border-radius: 4px;
}
.addmodalform{
    /* display: block; */
    /* width: 100%; */
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.addmodalinputcontainer{
    padding: 10px 0px;
    /* margin: 5px 2px; */
}
.dummyiconimg{
    width: 50px;
    height: 50px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center;  */
}
.icon-container{
    border: 1px solid black;
    height: 100%;
}
.ant-table-tbody > tr > td{
    border: 1px solid #f0f0f0 !important;
    text-align: center;
}
.ant-table-thead > tr > th{
    border: 1px solid #f0f0f0 !important;
    background: #f4f4f4;
    text-align: center;
    
}
.ant-table-thead > tr > th{
    /* font-weight: 600; */
    font-size: 20px;
    color: black;
}
.ant-table-thead > tr :hover{
    /* border: 1px solid #f0f0f0 !important; */
    background: #f4f4f4;
    /* text-align: center; */
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    display: none !important;
}
/* Romantic things management styling End */


/* navbar modifications styling start*/
.navbar .navbar-menu-wrapper{
    box-shadow: none;
}
.white-background{
    background: white !important;
    color: black !important;
}

.white-text{
    color: white !important;
    font-weight: 700 !important;
}

/* navbar modifications styling end*/

.option-container{
    border: 1px solid #ced4da;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
}
.suggesion-title{

}
.button-left-container{
    float: right;
}
.sidebar .nav .nav-item .menu-icon i{
    font-size: 1.2rem;
}



/* drop down styling start*/
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown{
    box-shadow: none ;
    background: linear-gradient(rgb(252,155,153),rgb(134,178,248)) !important;
}
.preview-list .preview-item .preview-item-content p{
    font-weight: 700 !important;
    font-size: 15px;
}
.dropdown-item:hover, .dropdown-item:focus{
    background: none !important;
}

/* drop down styling end*/
.sidebar .nav .nav-item .menu-icon{
    height: 35px;
    width: 35px;
}
.navbar .navbar-menu-wrapper .navbar-toggler{
    color: black;
}
.form-label{
    font-family: "Karla";
    font-size: 18px;

}
.navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right){
    font-size: 1.5rem;
}
.sidebar .sidebar-brand-wrapper{
    background: none;
}
.ant-collapse{
    border: none;
    font-size: 20px;
}
.card{
    border: none;
}
.ant-collapse > .ant-collapse-item{
    border: none;
}
.nav-shadow{
    box-shadow: 1px 0 10px 0 #556988;
}
.top-nav-shadow{
    /* box-shadow: 0px 2px 0px 0 #556988; */
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
}
/* dashboard modifications */
.icon.icon-box-user{
    width: 60px;
    height: 57px;
    background: rgba(0, 210, 91, 0.11);
    border-radius: 7px;
    color: #049b45;
}
.card-icon-red{
    color: #fc424a!important;
}
.card-icon-blue{
    color: #0090e7!important;
}
.card-icon-yello{
    color: #ffab00!important;
}
.card-icon-red{
    
}
.icon.icon-box-success{
    background: none;
}

/* breadcrumbs designing start */

.bread-crumbs{
    font-size: 18px;
    margin-bottom: 10px;
    
}
.bread-crumb-padding{
    margin: 0px 2px;
    color: rgb(46, 46, 46) !important;
    /* font-family: sans-serif; */
    font-size: 16px;
}
.icon-home-size{
    font-size: 18px;
    display: inline-block;
}
/* breadcrumbs designing end */
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding: 0;
}

/* .ant-table .ant-table-row:hover,
.ant-table .ant-table-row:target,
.ant-table .ant-table-row:focus{
    background: rgba(0,0,0,.05);
} */
.icon-size{
    font-size: 35px;
}
.green-icon{
    color: green;
}
.red-icon{
    color: red;
}
.form-element-container{
    padding: 15px 0px;
}
.title-prodile{
    display: block;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
}
.form-detail-title{
    font-size: 18px;
}
.profilepic{
    display: block;
    margin: auto;
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 50%;
}
.padding-right-button{
    padding-top:20px;
    padding-right: 200px;
}
.nocard-border {
    border: none ;
}
.change-text{
    color: #0090e7;
    text-decoration: underline;
}
.ant-modal-footer {
    display: none;
 }
 .Button-Cancel-Content{
    background: darkgrey;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    /* font-weight: 600; */
    margin: 20px 10px;
 }
 .Cancel-Content{
    display: block;
    padding: 7px 20px;
 }
 .Button-Create-Content{
    background: #5798ff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    /* font-weight: 600; */
    margin: 20px 10px;
 }
 .Create-Content{
    display: block;
    padding: 7px 20px;
 }
 .title-tab{
     display: inline-block;
     margin: 5px 20px 0px 30px;
     margin-right: 10px;
     font-family: "Arial" ;
    font-size: 20px;
    font-weight: 600px;
    padding:10px 20px;
    cursor: pointer;
 }
 .active-tab{
    display: inline-block;
    padding:10px 20px;
    margin: 20px 20px 0px 30px;
    font-family: "Arial" ;
   font-size: 20px;
   font-weight: 600px;
   background: #ffffff;
   color: black;
   border-top-left-radius: 9px;
   border-top-right-radius: 9px;
   cursor: pointer;
 }
 .container-tabs{
     background: #f4f4f4;
     width: 100%;
     color: black;
     border-top-left-radius: 9px;
     border-top-right-radius: 9px;
    /* margin:10px 0px; */
 }
 .romanticThings .ant-card-body{
     padding: 0px !important;
 }
 .form-container{
     padding: 20px;
 }
 .ant-table-thead .ant-table-cell{
     padding: 10px 16px;
 }
 .ant-table-tbody .ant-table-cell{
     padding: 10px 16px;
 }
 .card-padding{
     margin: 15px 0px !important;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.125);
    
    }
.ant-collapse{
     background: white;
 }
 .cke_bottom{
     display: none;
 }
 .ant-table-thead .ant-table-cell{
     text-align: center !important;
 }
 .edit-image{
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 137px;
    left: 220px;
    background: gainsboro;
    /* padding: 2px; */
    border-radius: 50%;

}
.f-20{
    font-size: 18px;
}
.ant-modal-title{
    font-size: 22px;
    padding: 10px 0px;
}
.model-text{
    font-size: 16px;
}
.red-bg{
    background: #f44336 !important;
    border-radius: 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    vertical-align: 4px;
}

/*  dropdown  */
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 4px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    height: 36px;
}
.red-colour{
    color: red;
}
a {
    text-decoration: none !important;
}
.ant-checkbox-wrapper{
    font-size: 15px;
}
.cp{
    cursor: pointer;
}
.h-20{
    height: 20px;
}
.submit-btn-container{
    width: 100%;
    padding-top: 20px;
}
.search-input .ant-input{
    height: 40px !important;
    border: 1px solid rgb(168, 168, 168);
    
}
.search-input .ant-input-affix-wrapper{
    height: 50px !important;
    border-bottom-right-radius:25px;
    border-bottom-left-radius:25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.sortingdropdown .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    height: 50px !important;
    border-bottom-right-radius:25px;
    border-bottom-left-radius:25px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.sortingdropdown .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    margin: auto !important;
}
.icon-btn{
    height: 50px;
    /* width: 50px; */
}
.btn-styling{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 1px solid rgb(173, 173, 173);
    width: 100%;
    height: max-content;
    border-radius: 5px;
}
.btn-links .ant-btn > span {
    margin: auto 0px !important;
    padding-left: 10px;
}
.bg-blue{
    color: gray;
}
.f-30{
    font-size: 30px;
}
.column-margin{
    margin: 0px 20px;
}
.edit-icon{
    height: 30px;
    width: 30px;
    position: absolute;
    right:20px;
    top:3px
}

/* forgot password start */
.forgot-title{
    color: #495057;
    font-weight: 600;
}
.forgot-text{
    font-size: 14px;
    color: #69717ab3;
    font-weight: 600;
}
/* forgot password end */